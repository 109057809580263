import { useEffect, useRef } from "react";
import useState from "react-usestateref";

export default function Loading({loaded}) {

    const bsx = 'BSXXXXXXXXXXXXXXX';
    const [loadingAmount, setLoadingAmount] = useState(3);
    const loadingInterval = useRef()
    const [localLoaded, setLocalLoaded, localLoadedRef] = useState(false);
    const loadingContainer = useRef()

    useEffect(() => {
        if(loadingInterval.current) clearInterval(loadingInterval.current)
        loadingInterval.current = setInterval(() => {
            if(localLoadedRef.current === false) {
                setLoadingAmount((loadingAmount) => loadingAmount > 17 ? 3 : loadingAmount + 1)
            }
            else {
                clearInterval(loadingInterval.current)
                loadingInterval.current = setInterval(() => {
                    setLoadingAmount((loadingAmount) => loadingAmount + 1)
                }, 50)
                setTimeout(() => {
                    clearInterval(loadingInterval.current)
                    if(loadingContainer.current) {
                        loadingContainer.current.style.opacity = 0
                        setTimeout(() => loadingContainer.current.style.display = 'none', 500)
                    }
                }, 1000)
            }
        }, 500)
    }, [])

    useEffect(() => {
        setLocalLoaded(loaded)
    }, [loaded])

    return (
        <div className="loading" ref={loadingContainer}>
            <p>
            {
                bsx.split('').map((letter, i) => (
                    <span style={{color: i >= loadingAmount ? '#3f3f3f':'#CCFF00'}}>{letter}</span>
                ))
            }
            </p>
        </div>
    )
}