import Marquee3k from "marquee3000";
import { useEffect, useState } from "react";
import "./App.scss";
import "./cursor/cursor.scss";
import ThreeCanvas from "./_ThreeCanvas";
import Cursor from "./cursor/cursor";
import Loading from "./loading";

function App() {

  useEffect(() => {
    Marquee3k.init();
  }, []);

  const [loaded, setLoaded] = useState(false)
  const [showCursor, setShowCursor] = useState(false)
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (loaded) setTimeout(() => setShowCursor(true), 7000);
  }, [loaded])

  useEffect(() => {
    setIsMobile(window.innerWidth < 600);
    window.addEventListener('resize', () => setIsMobile(window.innerWidth < 600))

    return () => window.removeEventListener('resize', () => setIsMobile(window.innerWidth < 600))
  }, [])

  return (
    <div className={`App app--${loaded ? 'loaded' : 'loading'}`}>
      <header
        className="header"
      >
        <h1 className="header__title">BSX</h1>
        <div className="header__text">
          <a href="mailto:hello@bsx.berlin" className="contact">
            <ul>
              <li><span className="list__num">01</span><p>UX/UX D<span className="letter">E</span><span className="number">3</span>SIGN</p></li>
              <li><span className="list__num">02</span><p>BR<span className="letter">A</span><span className="number">4</span>NDING</p></li>
              <li><span className="list__num">03</span><p>W<span className="letter">E</span><span className="number">3</span>B D<span className="letter">E</span><span className="number">3</span>V<span className="letter">E</span><span className="number">3</span>LOPM<span className="letter">E</span><span className="number">3</span>NT</p></li>
            </ul>
            <div className="marquee3k"><p>Let's work together</p></div>
          </a>
        </div>
      </header>
      <Loading loaded={loaded} />
      {showCursor && <Cursor />}
      <main>
        {/* <ThreeCanvas isAscii={true}/> */}
        {!isMobile ? (
          <ThreeCanvas isAscii={false} loaded={loaded} setLoaded={setLoaded} />
        ) : (
          <video autoPlay loop muted playsInline className={`bg-vid bg-vid--${loaded ? 'loaded':'loading'}`} onCanPlayThrough={() => setLoaded(true)}>
            <source src="/bg_long.mp4" />
          </video>
        )}
        <div className="about">
          We are a creative studio based in Berlin<br />
          specialised in design strategies & concepts,<br />
          webdesign (UX/UI) and web-development,<br />
          creative coding, print and visual identities.
        </div>
      </main>
      <footer>
        <nav>
          <ul>
            <li><a href="https://www.instagram.com/bsx.berlin/" target="_blank"><img src="/insta.svg" /></a></li>
            <li><a href="mailto:hello@bsx.berlin" target="_blank"><img src="/mail.svg" /></a></li>
          </ul>
        </nav>
      </footer>
    </div>
  );
}

export default App;
