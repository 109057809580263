import { useEffect, useRef } from "react"
import useState from "react-usestateref";

export default function Cursor() {

    const heartAmount = 10
    const [cursorCoord0, setCursorCoord0] = useState([-100, -100])
    const [cursorCoord1, setCursorCoord1] = useState([-100, -100])
    const [cursorCoord2, setCursorCoord2] = useState([-100, -100])
    const [cursorCoord3, setCursorCoord3] = useState([-100, -100])
    const [cursorCoord4, setCursorCoord4] = useState([-100, -100])
    const [cursorCoord5, setCursorCoord5] = useState([-100, -100])
    const [cursorCoord6, setCursorCoord6] = useState([-100, -100])
    const [cursorCoord7, setCursorCoord7] = useState([-100, -100])
    const [cursorCoord8, setCursorCoord8] = useState([-100, -100])
    const [cursorCoord9, setCursorCoord9] = useState([-100, -100])

    const [cursorMoving0, setCursorMoving0] = useState(false)
    const [cursorMoving1, setCursorMoving1] = useState(false)
    const [cursorMoving2, setCursorMoving2] = useState(false)
    const [cursorMoving3, setCursorMoving3] = useState(false)
    const [cursorMoving4, setCursorMoving4] = useState(false)
    const [cursorMoving5, setCursorMoving5] = useState(false)
    const [cursorMoving6, setCursorMoving6] = useState(false)
    const [cursorMoving7, setCursorMoving7] = useState(false)
    const [cursorMoving8, setCursorMoving8] = useState(false)
    const [cursorMoving9, setCursorMoving9] = useState(false)

    const movingTimeout0 = useRef()
    const movingTimeout1 = useRef()
    const movingTimeout2 = useRef()
    const movingTimeout3 = useRef()
    const movingTimeout4 = useRef()
    const movingTimeout5 = useRef()
    const movingTimeout6 = useRef()
    const movingTimeout7 = useRef()
    const movingTimeout8 = useRef()
    const movingTimeout9 = useRef()

    const handleCursorMove = (setCursorMoving, movingTimeout) => {
        setCursorMoving(true)
        if (movingTimeout.current) clearTimeout(movingTimeout.current)
        movingTimeout.current = setTimeout(() => setCursorMoving(false), 50)
    }

    const changeCoord = (e) => {
        // handleCursorMove()
        const newCoord = [e.clientX, e.clientY]
        setTimeout(() => { setCursorCoord0(newCoord); handleCursorMove(setCursorMoving0, movingTimeout0) }, 0);
        setTimeout(() => { setCursorCoord1(newCoord); handleCursorMove(setCursorMoving1, movingTimeout1) }, 100);
        setTimeout(() => { setCursorCoord2(newCoord); handleCursorMove(setCursorMoving2, movingTimeout2) }, 200);
        setTimeout(() => { setCursorCoord3(newCoord); handleCursorMove(setCursorMoving3, movingTimeout3) }, 300);
        setTimeout(() => { setCursorCoord4(newCoord); handleCursorMove(setCursorMoving4, movingTimeout4) }, 400);
        setTimeout(() => { setCursorCoord5(newCoord); handleCursorMove(setCursorMoving5, movingTimeout5) }, 500);
        setTimeout(() => { setCursorCoord6(newCoord); handleCursorMove(setCursorMoving6, movingTimeout6) }, 600);
        setTimeout(() => { setCursorCoord7(newCoord); handleCursorMove(setCursorMoving7, movingTimeout7) }, 700);
        setTimeout(() => { setCursorCoord8(newCoord); handleCursorMove(setCursorMoving8, movingTimeout8) }, 800);
        setTimeout(() => { setCursorCoord9(newCoord); handleCursorMove(setCursorMoving9, movingTimeout9) }, 900);
    }

    useEffect(() => {
        window.addEventListener('mousemove', changeCoord)

        return () => {
            window.removeEventListener('mousemove', changeCoord)
        }
    }, [])

    return (
        <>
                <div className="cursor" style={{
                    left: `${cursorCoord0[0]}px`,
                    top: `${cursorCoord0[1]}px`,
                    opacity: cursorMoving0 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord1[0]}px`,
                    top: `${cursorCoord1[1]}px`,
                    opacity: cursorMoving1 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord2[0]}px`,
                    top: `${cursorCoord2[1]}px`,
                    opacity: cursorMoving2 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord3[0]}px`,
                    top: `${cursorCoord3[1]}px`,
                    opacity: cursorMoving3 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord4[0]}px`,
                    top: `${cursorCoord4[1]}px`,
                    opacity: cursorMoving4 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord5[0]}px`,
                    top: `${cursorCoord5[1]}px`,
                    opacity: cursorMoving5 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord6[0]}px`,
                    top: `${cursorCoord6[1]}px`,
                    opacity: cursorMoving6 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord7[0]}px`,
                    top: `${cursorCoord7[1]}px`,
                    opacity: cursorMoving7 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord8[0]}px`,
                    top: `${cursorCoord8[1]}px`,
                    opacity: cursorMoving8 ? 1:0
                }}>
                   {'<3'}
                </div>
                <div className="cursor" style={{
                    left: `${cursorCoord9[0]}px`,
                    top: `${cursorCoord9[1]}px`,
                    opacity: cursorMoving9 ? 1:0
                }}>
                   {'<3'}
                </div>
        </>
    )
}