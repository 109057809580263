import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Environment, AccumulativeShadows, RandomizedLight, CameraControls } from '@react-three/drei'
import { useRef, useEffect, Suspense } from 'react'
import useState from "react-usestateref";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { MeshTransmissionMaterial } from "./MeshTransmissionMaterial";
import * as THREE from "three";

function Face({ setLoaded }) {

  const fbx = useLoader(FBXLoader, '/kiss2.fbx')

  if (fbx) {
      setLoaded(true)
  } else {
      // console.log('not loaded')
  }

  const glass = new THREE.MeshPhysicalMaterial({
    color:'black',
    metalness: 0.9,
    roughness: 0.1,
    envMapIntensity: 5,
    clearcoat: 1,
    transparent: false,
    opacity: 1,
    reflectivity: 1,
    ior: 0.5,
    depthTest: true,
  });


  fbx.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
      child.material = glass;
    }
  })

  return <primitive object={fbx} />
}

export default function ThreeFace({ setLoaded, loaded }) {

  const logoRef = useRef()
  const [rotationState, setRotationState] = useState(0.7);
  const [zoom, setZoom] = useState(-1000)
  const intervalRef = useRef()
  const [intervalIndex, setIntervalIndex, intervalIndexRef] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0)

  const mouseMove = (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const posX = (((clientX - window.innerWidth / 2) / window.innerWidth) * 1.55) * 2;
    setRotationState(posX + 0.7);
  };

  const startMouse = () => {
    if(intervalRef.current) clearInterval(intervalRef.current)
    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("touchmove", mouseMove);
  }

  const startAnim = () => {
    if(intervalRef.current) clearInterval(intervalRef.current)
    const extraZoom = windowWidth > 900 ? 8.2 : windowWidth > 400 ? 3.5 : 4.2
    intervalRef.current = setInterval(() => {
      setRotationState((rotationState) => rotationState + (0.15 * (1.3 - intervalIndexRef.current)))
      setZoom((zoom) => zoom + (extraZoom * (1.3 - intervalIndexRef.current)))
      setIntervalIndex((intervalIndex) => intervalIndex + 0.01)
    }, windowWidth > 900 ? 60:100)
    setTimeout(() => {
      startMouse()
    }, 7000)
  }

  // CREATE TEXTURES FROM IMAGE
    useEffect(() => {
      setWindowWidth(window.innerWidth)
      if(loaded) setTimeout(() => startAnim(), 2000)
    }, [loaded])

  return (
    <>
      <Canvas shadow id="three-container--glass" className="three-container" style={{opacity: loaded ? 1:0}}>
        <Suspense fallback={null}>
          <group
            castShadow receiveShadow
            rotation={[0, rotationState, 0.2]}
            scale={[0.2,0.2,0.2]}
            position={[-0.4,-3,zoom]}
            logoRef={logoRef}
          >
            <Face setLoaded={setLoaded}/>
          </group>
        </Suspense>
        <Environment files="/gry.hdr" background={false} color="white"/>
        <AccumulativeShadows color="white" position={[0, -1, 0]} frames={100} opacity={0.75}>
          <RandomizedLight radius={10} position={[-5, 5, 2]} />
        </AccumulativeShadows>
      </Canvas>
    </>
  )
}